<template>
  <div id="feed">
    <!-- Appmm Z -->

    <!-- MAIN CONTENT -->
    <div>
      <template v-if="loading">
        <div class="px-3" v-for="(n, i) in 10" :key="i">
          <v-skeleton-loader
            :key="i"
            :loading="loading"
            height="300px"
            type="card-heading, image, text"
          >
          </v-skeleton-loader>
        </div>
      </template>

      <div v-show="!loading">
        <!-- Top spinner -->

        <template v-if="moreLoadingTop">
          <div class="d-flex mt-4 pa-3 justify-center align-center">
            <v-progress-circular
              :width="3"
              :size="40"
              color="primary lighten-1"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>

        <!-- Top spinner -->
        <router-link to="/new/circle" class="text-center">
          <v-img
            class="mx-auto"
            position="top"
            src="https://i.ibb.co/vkqyxKr/Copy-of-Cyan-Smartphone-Bright-and-Bold-Coronavirus-Social-Media-Post.png"
            :max-height="$vuetify.breakpoint.mobile ? 134 : 300"
          ></v-img>
        </router-link>

        <info-card v-if="trendingPostSlug" :slug="trendingPostSlug"></info-card>
        <post
          v-for="(post, i) in feed"
          v-intersect.quiet="{
            handler: currentPost,
            options: {
              threshold: 1.0
            }
          }"
          :key="i"
          :index="i"
          :post="post"
          :id="post.id"
          :insideViewPost="false"
        ></post>

        <!-- Bottom spinner -->

        <template v-if="moreLoadingBottom">
          <div class="d-flex my-4 pa-3 justify-center align-center">
            <v-progress-circular
              :width="3"
              :size="40"
              color="primary lighten-1"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>

        <!-- Bottom spinner -->

        <div v-if="postsHafFinish" class="text-center grey--text caption py-5">
          that's all folks!
          <p class="mb-2 mt-1">
            <img src="https://i.ibb.co/YPgGPLk/logo.png" height="12px" />
          </p>
        </div>
      </div>

      <div
        v-if="errorLoadingFeed"
        class="d-flex text-center justify-center align-center"
        style="height: 480px"
      >
        <div class="text-center">
          <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" />
          <span
            class="subtitle-2 d-block text-light lighten mt-5 mb-2"
            style="width: 200px"
            >Couldn't get Feed :/</span
          >
          <p class="caption secondary--text">
            Please try again
          </p>
        </div>
      </div>

      <!-- TODO: Change this to a proper 'no results' kini :)  when we are not getting feed -->
      <div
        v-if="
          user &&
            user.circles.length == 0 &&
            !errorLoadingFeed &&
            feed.length == 0
        "
        class="d-flex text-center justify-center align-center"
        style="height: 480px"
      >
        <div class="text-center">
          <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" />
          <span
            class="subtitle-2 d-block text-light lighten mt-5 mb-2"
            style="width: 200px"
            >You haven't joined any circles yet</span
          >
          <p class="caption secondary--text">
            <v-btn
              depressed
              class="white--text"
              color="pink darken-2"
              to="/circles"
            >
              See Circles
            </v-btn>
          </p>
        </div>
      </div>

      <v-card
        v-if="!loggedIn && feed.length > 0"
        color="primary lighten-3"
        class="ma-5 elevation-0"
        height="200px"
      >
        <v-card-text
          class="text-center d-flex align-center"
          style="width: 100%; height: 100%"
        >
          <v-row no-gutters>
            <v-col cols="12" class="mb-2">
              <span class="text--black font-weight-bold" style="font-size: 18px"
                >Join Bubbl to see more content</span
              >
            </v-col>
            <v-col cols="12">
              <v-btn color="amber" @click="goToAuth">
                Join
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div
        v-if="!errorLoadingFeed && loggedIn && feed.length == 0"
        class="d-flex text-center justify-center align-center"
        style="height: 480px"
      >
        <div class="text-center">
          <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" />
          <span
            class="subtitle-2 d-block text-light lighten mt-5 mb-2"
            style="width: 200px"
            >Nothing here :/</span
          >
          <p class="caption secondary--text">
            Please try again
          </p>
        </div>
      </div>
    </div>

    <!-- MAIN CONTENT -->
  </div>
</template>
<script>
import http from '@/plugins/http';
import Post from '@/components/post/post.vue';
// import InfoCard from '@/components/misc/info-card.vue';
import { parseAsync, getPayload } from '@/store';
// import fetcher from '@/utils/fetcher';
export default {
  components: {
    Post,
    InfoCard: () => import('@/components/misc/info-card.vue')
  },
  data() {
    return {
      feed: [],
      loading: false,
      errorLoadingFeed: false,
      moreLoadingBottom: true,
      moreLoadingTop: false,
      postsHafFinish: false,
      atBottom: false,
      atTop: false,
      bottom: false,
      top: false,
      trendingPostSlug: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  watch: {
    '$store.state.newPosts': function(newposts) {
      if (newposts) {
        // this.getFeed();
        this.$store.dispatch('TOGGLE_NEW_POSTS');
      }
    },
    loading: function() {
      window.scrollTo(0, 1);
    },
    bottom: function(bottom) {
      if (bottom) {
        this.fetcherNext();
      }
    },
    top: function(top) {
      if (top) {
        this.fetcherPrevious();
      }
    }
  },
  methods: {
    getFeed(
      page = 1,
      network = true,
      bottom = true,
      spinner = { showTop: false, showBottom: false }
    ) {
      console.log('bottom: ', bottom);

      if (!network) {
        let feed = window.localStorage.getItem(
          `bubbl_user_feed_partition-${page}`
        );

        // this.feed = JSON.parse(feed);
        parseAsync(feed)
          .then(posts => {
            if (bottom) {
              this.feed.push(...posts.posts);
              console.log('pushing from local, bottom is? ', bottom);

              this.atBottom = false;
              this.moreLoadingBottom = false;
              this.bottom = false;
            } else {
              this.feed.unshift(...posts.posts);
              console.log('unshifting from local, bottom is? ', bottom);

              this.atTop = false;
              this.moreLoadingTop = false;
              this.top = false;
            }

            this.$store.dispatch('PERSIST_FEED', {
              posts: posts.posts,
              page,
              next_page: posts.next_page,
              previous_page: posts.previous_page,
              partitions_size: posts.posts.length
            });
            this.$root.$emit('feed-populated');

            window.localStorage.setItem('current_partition', page);
          })
          .catch(error => {
            console.log('error parsing posts! =>', error);
            if (bottom) {
              this.atBottom = false;
              this.moreLoadingBottom = false;
              this.bottom = false;
            } else {
              this.atTop = false;
              this.moreLoadingTop = false;
              this.top = false;
            }
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Something went wrong, please try again',
              style: 'error'
            });
          });
        return;
      }

      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }

      if (spinner.showBottom || spinner.showTop) {
        this.moreLoadingBottom = spinner.showBottom;
        this.moreLoadingTop = spinner.showTop;
      } else {
        this.loading = true;
      }

      http
        .get(`${page ? '/v1/user/feed/?page=' + page : '/v1/user/feed/'}`, {
          headers
        })
        .then(response => {
          if (this.$store.state.loggedIn) {
            if (bottom) {
              console.log('pushing from network, bottom is? ', bottom);

              this.feed.push(...response.data.results);
              this.atBottom = false;
              this.moreLoadingBottom = false;
              this.bottom = false;
            } else {
              console.log('unshifting from network, bottom is? ', bottom);

              this.feed.unshift(...response.data.results);
              this.atTop = false;
              this.moreLoadingTop = false;
              this.top = false;
            }

            // this.feed = [...this.feed, ...response.data.results];
            const p = getPayload(
              response.data.results,
              response.data.next,
              page
            );

            window.localStorage.setItem('current_partition', page);

            this.$store.dispatch('PERSIST_FEED', p);
          } else {
            const feeds = response.data;
            this.feed = Object.keys(feeds).reduce((sum, k) => {
              return sum.concat([...feeds[k]]);
            }, []);

            // this.$store.dispatch('SET_FEED', [...this.feed]);
          }
        })
        .catch(response => {
          this.errorLoadingFeed = true;
          if (bottom) {
            this.atBottom = false;
            this.moreLoadingBottom = false;
            this.bottom = false;
          } else {
            this.atTop = false;
            this.moreLoadingTop = false;
            this.top = false;
          }
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error fetching Posts',
            style: 'error'
          });
          console.log('Error ! =>', response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToAuth() {
      this.$router.push({ name: 'Auth' });
    },
    goToIntro() {
      if (this.$gtag) {
        this.$gtag.event('visited_intro_from_topbar_in_feed', {
          event_label: 'Intro'
        });
      }

      this.$router.push({ name: 'Intro' });
    },
    nextPartitionIndex() {
      let current_partition_index = this.currentPartitionIndex() || 1;

      // let nextPartitionIndex =
      let current_partition_json = JSON.parse(
        window.localStorage.getItem(
          `bubbl_user_feed_partition-${current_partition_index}`
        )
      ) || { next_page: false };

      return current_partition_json.next_page;
    },
    currentPartitionIndex() {
      let current_partition = window.localStorage.getItem('current_partition');

      return parseInt(current_partition);
    },
    previousPartitionIndex() {
      let current_partition_index = this.currentPartitionIndex();

      // let nextPartitionIndex =
      let previous_partition_index = JSON.parse(
        window.localStorage.getItem(
          `bubbl_user_feed_partition-${current_partition_index}`
        )
      ) || { previous_page: false };

      return previous_partition_index.previous_page;
    },
    scroller() {
      // let bottomOfWindow =
      //   document.documentElement.scrollTop + window.innerHeight >=
      //   document.documentElement.offsetHeight - 50;

      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      let bottomOfWindow = bottomOfPage || pageHeight < visible;

      let topOfWindow = document.documentElement.scrollTop < 1;

      if (bottomOfWindow && !this.atBottom) {
        this.bottom = true;
        return;
      }

      let current_partition = window.localStorage.getItem('current_partition');

      if (current_partition == '1' || !current_partition) {
        return;
      }

      if (topOfWindow && !this.atTop) {
        this.top = true;
        return;
      }
    },
    fetcherNext: function() {
      this.atBottom = true;
      this.moreLoadingBottom = true;
      this.postsHafFinish = false;

      let next_partition_index = this.nextPartitionIndex();

      if (next_partition_index == null) {
        console.log('posts haf finished :)');
        this.moreLoadingBottom = false;
        this.postsHafFinish = true;
        return;
      }
      let next_partition = window.localStorage.getItem(
        `bubbl_user_feed_partition-${next_partition_index}`
      );

      if (next_partition) {
        this.getFeed(next_partition_index, false, true, {
          showTop: false,
          showBottom: true
        });
      } else {
        // Check if the current partition is saved already...

        this.getFeed(next_partition_index, true, true, {
          showTop: false,
          showBottom: true
        });
      }

      console.log('next partition =>', next_partition_index);
    },
    fetcherPrevious: function() {
      // Maybe separate the two fetchers into separate functions ...

      this.atTop = true;
      this.postsHafFinish = false;

      let previous_partition_index = this.previousPartitionIndex();

      if (previous_partition_index == null) {
        console.log('no previous posts :/');
        this.moreLoadingTop = false;
        this.postsHafFinish = true;
        return;
      }

      let previous_partition = window.localStorage.getItem(
        `bubbl_user_feed_partition-${previous_partition_index}`
      );

      if (previous_partition) {
        console.log('fetching from local..', previous_partition_index);
        this.getFeed(previous_partition_index, false, false, {
          showTop: true,
          showBottom: false
        });
      } else {
        console.log('fetching from network..', previous_partition_index);

        this.getFeed(previous_partition_index, true, false, {
          showTop: true,
          showBottom: false
        });
      }

      console.log('previous partition =>', previous_partition_index);
    },
    currentPost: function(entries) {
      if (entries[0].isIntersecting) {
        window.localStorage.setItem(
          'last_viewed_feed_post',
          `#${entries[0].target.id}`
        );
      }
    },
    getData() {
      const url = 'https://bubbl-100d.restdb.io/rest/bubbl-data-sidebar?max=2';
      // const config = {
      //   headers: {
      //     'x-apikey': '9d61f96a9095ac4d9430ba3242ab14596f6fb',
      //   }
      // };
      http
        .get(url)
        .then(response => {
          if (response.data.success) {
            this.trendingPostSlug = response.data[0].post_slug;
          }
        })
        .catch(err => {
          this.trendingPostSlug = false;
          console.log(err.data);
        });
    }
  },
  mounted() {
    // this.getData();

    let current_partition_index = this.currentPartitionIndex();

    let current_partition = window.localStorage.getItem(
      `bubbl_user_feed_partition-${current_partition_index}`
    );

    if (current_partition) {
      console.log('inside here');
      // If there is a current partition just load it...
      this.getFeed(current_partition_index, false, true, {
        showTop: false,
        showBottom: false
      });
    } else {
      this.getFeed(1, true, true);
    }

    window.addEventListener('scroll', this.scroller);
  },
  destroyed() {
    console.log('removing scroll listener!');
    window.removeEventListener('scroll', this.scroller);
  },
  head: {
    title: 'Feed'
  }
};
</script>
<style scoped>
#feed {
  margin-bottom: 60px !important;
}
</style>
