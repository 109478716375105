var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"feed"}},[_c('div',[(_vm.loading)?_vm._l((10),function(n,i){return _c('div',{key:i,staticClass:"px-3"},[_c('v-skeleton-loader',{key:i,attrs:{"loading":_vm.loading,"height":"300px","type":"card-heading, image, text"}})],1)}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(_vm.moreLoadingTop)?[_c('div',{staticClass:"d-flex mt-4 pa-3 justify-center align-center"},[_c('v-progress-circular',{attrs:{"width":3,"size":40,"color":"primary lighten-1","indeterminate":""}})],1)]:_vm._e(),_c('router-link',{staticClass:"text-center",attrs:{"to":"/new/circle"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"position":"top","src":"https://i.ibb.co/vkqyxKr/Copy-of-Cyan-Smartphone-Bright-and-Bold-Coronavirus-Social-Media-Post.png","max-height":_vm.$vuetify.breakpoint.mobile ? 134 : 300}})],1),(_vm.trendingPostSlug)?_c('info-card',{attrs:{"slug":_vm.trendingPostSlug}}):_vm._e(),_vm._l((_vm.feed),function(post,i){return _c('post',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
          handler: _vm.currentPost,
          options: {
            threshold: 1.0
          }
        }),expression:"{\n          handler: currentPost,\n          options: {\n            threshold: 1.0\n          }\n        }",modifiers:{"quiet":true}}],key:i,attrs:{"index":i,"post":post,"id":post.id,"insideViewPost":false}})}),(_vm.moreLoadingBottom)?[_c('div',{staticClass:"d-flex my-4 pa-3 justify-center align-center"},[_c('v-progress-circular',{attrs:{"width":3,"size":40,"color":"primary lighten-1","indeterminate":""}})],1)]:_vm._e(),(_vm.postsHafFinish)?_c('div',{staticClass:"text-center grey--text caption py-5"},[_vm._v(" that's all folks! "),_vm._m(0)]):_vm._e()],2),(_vm.errorLoadingFeed)?_c('div',{staticClass:"d-flex text-center justify-center align-center",staticStyle:{"height":"480px"}},[_vm._m(1)]):_vm._e(),(
        _vm.user &&
          _vm.user.circles.length == 0 &&
          !_vm.errorLoadingFeed &&
          _vm.feed.length == 0
      )?_c('div',{staticClass:"d-flex text-center justify-center align-center",staticStyle:{"height":"480px"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"py-3 empty-folder",attrs:{"src":"/img/icons/empty-folder.png"}}),_c('span',{staticClass:"subtitle-2 d-block text-light lighten mt-5 mb-2",staticStyle:{"width":"200px"}},[_vm._v("You haven't joined any circles yet")]),_c('p',{staticClass:"caption secondary--text"},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"pink darken-2","to":"/circles"}},[_vm._v(" See Circles ")])],1)])]):_vm._e(),(!_vm.loggedIn && _vm.feed.length > 0)?_c('v-card',{staticClass:"ma-5 elevation-0",attrs:{"color":"primary lighten-3","height":"200px"}},[_c('v-card-text',{staticClass:"text-center d-flex align-center",staticStyle:{"width":"100%","height":"100%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text--black font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("Join Bubbl to see more content")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"amber"},on:{"click":_vm.goToAuth}},[_vm._v(" Join ")])],1)],1)],1)],1):_vm._e(),(!_vm.errorLoadingFeed && _vm.loggedIn && _vm.feed.length == 0)?_c('div',{staticClass:"d-flex text-center justify-center align-center",staticStyle:{"height":"480px"}},[_vm._m(2)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2 mt-1"},[_c('img',{attrs:{"src":"https://i.ibb.co/YPgGPLk/logo.png","height":"12px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"py-3 empty-folder",attrs:{"src":"/img/icons/empty-folder.png"}}),_c('span',{staticClass:"subtitle-2 d-block text-light lighten mt-5 mb-2",staticStyle:{"width":"200px"}},[_vm._v("Couldn't get Feed :/")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Please try again ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"py-3 empty-folder",attrs:{"src":"/img/icons/empty-folder.png"}}),_c('span',{staticClass:"subtitle-2 d-block text-light lighten mt-5 mb-2",staticStyle:{"width":"200px"}},[_vm._v("Nothing here :/")]),_c('p',{staticClass:"caption secondary--text"},[_vm._v(" Please try again ")])])}]

export { render, staticRenderFns }